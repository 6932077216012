import React, { useEffect, useState } from "react"
import "./Monitoring.less"
import { useConfig } from "../../contexts/configContext"
import { useSnackbar } from "../../contexts/snackbarContext"
import Spinner from "../UI/Spinner/Spinner"
import useHttpApi from "../useHttpApi"
import ListTable from "../SharedComponents/ListTable"

function Monitoring(props) {
  const [alarmTable, setalarmTable] = useState([])
  const [fetchedAlarmTable, setfetchedAlarmTable] = useState(null)
  const config = useConfig()
  const snackbar = useSnackbar()
  const httpApi = useHttpApi()

  const header = [
    {
      title: "Object Type",
      field: "ObjectType",
      size: "8%",
    },
    {
      title: "Object ID",
      field: "ObjectId",
      size: "15%",
    },
    {
      title: "Involved Countpart",
      field: "Destination",
      size: "20%",
    },
    {
      title: "Alarm ID",
      field: "Id",
      size: "20%",
    },
    {
      title: "Alarm Cause",
      field: "Cause",
      size: "10%",
    },
    {
      title: "Severity",
      field: "Severity",
      size: "10%",
    },
    {
      title: "Event Time",
      field: "EventTime",
      size: "14%",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "show",
    },
  ]

  const jsonExample1 = {
    csepp1: {
      "1.3.6.1.4.1.9148.3.2.1.0.1": [
        {
          Destination: "dest2.com",
          Id: "844b4fa3-811b-4846-8f22-9369f03b0be9",
          EventTime: "2023-07-13T10:59:47.6249851+02:00",
        },
      ],
      "1.3.6.1.4.1.9148.3.2.1.0.2": [
        {
          Destination: "dest3.com",
          Id: "247d3d2d-62c2-4c28-a075-1f786b6a87ab",
          EventTime: "2023-07-13T11:08:23.6249851+02:00",
        },
      ],
    },
    "192.168.1.5": { "1.3.6.1.4.1.9148.3.2.1.0.3": [] },
  }

  const jsonExample2 = {
    forwarding: {
      "1.3.6.1.4.1.9148.3.2.1.0.3": [
        {
          Destination: "domain",
          Id: "72d25ea4-9fa9-4a2a-b9a3-e5ff1ad23e65",
          EventTime: "2023-07-20T13:23:13.3986335+00:00",
        },
        {
          Destination: "dest22.com",
          Id: "a1b3f33d-7388-49ff-97a3-f3ab1fe5d875",
          EventTime: "0001-01-01T00:00:00",
        },
        {
          Destination: "dest222.com",
          Id: "de00aa46-1a6b-44f8-8b57-b6d203753983",
          EventTime: "0001-01-01T00:00:00",
        },
      ],
    },
    proxy: {
      "1.3.6.1.4.1.9148.3.2.1.0.3": [
        {
          Destination: "domain",
          Id: "a5a321b1-3548-4b45-8bf3-aa94e3d1c9a2",
          EventTime: "2023-07-20T13:23:20.8086362+00:00",
        },
        {
          Destination: "dest22.com",
          Id: "0c499aa4-1d58-4b2f-91c9-5f011ed6e87a",
          EventTime: "0001-01-01T00:00:00",
        },
        {
          Destination: "dest1.com",
          Id: "b37931bf-eca0-4c90-a63a-4c363755deb7",
          EventTime: "0001-01-01T00:00:00",
        },
      ],
    },
  }

  const jsonExample3 = {
    forwarding: {
      "1.3.6.1.4.1.9148.3.2.1.0.3": [
        {
          Destination: "domain",
          Id: "73e60ca6-905a-422e-adeb-1d23b3afe39b",
          EventTime: "0001-01-01T00:00:00",
        },
      ],
    },
    proxy: {},
    "111-111": {
      "1.3.6.1.4.1.9148.3.2.1.0.2": [
        {
          Destination: "mnc000.mcc000.3gppnetwork.com",
          Id: "fd8f4749-0c1f-415b-8387-c690f8a0dbe1",
          EventTime: "2023-07-20T15:44:03.2708979+00:00",
        },
      ],
    },
  }

  const jsonExample4 = {
    forwarding: {
      "1.3.6.1.4.1.9148.3.2.1.0.3": [
        {
          Destination: "domain",
          Id: "73e60ca6-905a-422e-adeb-1d23b3afe39b",
          EventTime: "0001-01-01T00:00:00",
        },
      ],
    },
    proxy: {
      "1.3.6.1.4.1.9148.3.2.1.0.3": [
        {
          Destination: "domain",
          Id: "ec51e6e3-c3df-476f-a909-c9d5351d3445",
          EventTime: "0001-01-01T00:00:00",
        },
      ],
    },
    "111-111": {
      "1.3.6.1.4.1.9148.3.2.1.0.2": [
        {
          Destination: "mnc000.mcc000.3gppnetwork.com",
          Id: "fd8f4749-0c1f-415b-8387-c690f8a0dbe1",
          EventTime: "2023-07-20T15:44:03.2708979+00:00",
        },
      ],
      "1.3.6.1.4.1.9148.3.2.1.0.1": [
        {
          Destination: "mnc000.mcc000.3gppnetwork.com",
          Id: "898e7dd8-f918-4381-8fc5-9d13c60884f1",
          EventTime: "0001-01-01T00:00:00",
        },
      ],
    },
  }

  const jsonExample5 = {
    csepp: {},
    psepp: {
      "1.3.6.1.4.1.9148.3.2.1.0.3": [
        {
          Destination: "10.107.101.146",
          NotificationId: 636217681,
          Id: 5301,
          EventTime: "03/12/2024 09:27 AM",
          model: {
            alarmId: "5301",
            alarmType: "COMMUNICATION_ALARM",
            probableCause: "SEPP NOT RESPONDING",
            specificProblem: "SEPP DOWN",
            perceivedSeverity: "CRITICAL",
            correlatedNotifications: [],
            additionalInformation: { counterPart: "10.107.101.146" },
            notificationId: "636217681",
            notificationType: "notifyNewAlarm",
            eventTime: "2024-03-12 09:27:52+00",
            systemDN: "",
            href: "https://sepp-2484959ca5534962b829cea918c5627a.5gef.net/home",
            objectClass: "SEPP",
            objectInstance: "sepp-dev-1111-pSEPP",
          },
        },
      ],
    },
  }

  const alarmCauseTable = config.configInfo.mibTable

  useEffect(() => {
    props.setBreadcrumb("", "Monitoring", "")
  }, [])

  useEffect(() => {
    getAlarmTable().then((res) => {
      if (res !== -1) setfetchedAlarmTable(res)
    })
  }, [])

  const getAlarmTable = async () => {
    const data = await httpApi.callGetAPI({
      url: `json/${config.configInfo.protectionGroupID}event-table`,
      snack: { info: { status: "info", message: `There are no alarm data to show.` } },
    })
    return data || -1
  }

  const buildAlarmTable = (fetchedAlarmTable) => {
    //getting the mib object
    const tmpAlarmTable = Object.entries(fetchedAlarmTable).map((k, v) => {
      const seppType = k[0]
      const mib = k[1]
      //extracting the mib id and the alarm object
      return Object.entries(mib).map((k1, v1) => {
        const mibId = k1[0]
        const alarmObject = k1[1]
        //build the alarm object by checking the mib id on the mibTable (in the config file)
        return alarmObject.map((alarm) => {
          return buildAlarmObject(mibId, alarm, seppType)
        })
      })
    })

    //some adjustments
    let alarmTable = []
    tmpAlarmTable.forEach((at) => {
      at.forEach((alarm) => {
        alarmTable = [...alarmTable, alarm]
      })
    })

    return alarmTable
  }

  const buildAlarmObject = (mibId, alarm, seppType) => {
    if (!alarmCauseTable) throw new Error("cannot find the <i>MIB Table</i> inside the <i>config</i> file.")

    const foundMib = alarmCauseTable.find((act) => act.id === mibId)
    if (!foundMib) {
      alarm.Cause = <span style={{ color: "red" }}>NOT VALID MIB</span>
      alarm.Severity = <span style={{ color: "red" }}>NOT VALID MIB</span>
      alarm.ObjectType = <span style={{ color: "red" }}>NOT VALID MIB</span>
      alarm.ObjectId = <span style={{ color: "red" }}>NOT VALID MIB</span>
    } else {
      const objectType = seppType === "forwarding" ? "pSEPP" : seppType === "proxy" ? "cSEPP" : foundMib.objectType
      alarm.Cause = foundMib.cause
      alarm.Severity = foundMib.severity
      alarm.ObjectType = objectType
      alarm.ObjectId =
        config.namespace === "ERROR"
          ? "ERROR"
          : config.namespace === "NOT SET"
          ? "NOT SET"
          : `${config.namespace}-${objectType}`
    }
    return alarm
  }

  useEffect(() => {
    if (fetchedAlarmTable) {
      try {
        const _alarmTable = buildAlarmTable(fetchedAlarmTable)
        let flattenAlarmTable = []
        _alarmTable.forEach((row) => {
          row.forEach((alarm) => {
            flattenAlarmTable.push(alarm)
          })
        })
        console.log(flattenAlarmTable)
        setalarmTable(flattenAlarmTable)
      } catch (error) {
        snackbar.setSnackbarInfo({
          status: "error",
          message: `<b>An error occurred:</b> ${error.toString().replace("Error:", "")}`,
        })
      }
    }
  }, [config.namespace, fetchedAlarmTable])

  return (
    <>
      {config.loading ? (
        <Spinner show={config.loading} />
      ) : (
        <div className="page">
          {alarmTable.length > 0 ? (
            <ListTable
              elements={alarmTable}
              header={header}
              sortable
              searchable
              collapsible
              dense
              cellActions={[
                {
                  elementName: "alarm",
                  type: "show",
                  size: header.find((h) => h.hasOwnProperty("type") && h.type === "show")?.size,
                },
              ]}
            ></ListTable>
          ) : (
            <h3 className="no-table-data">No Alarms data available</h3>
          )}
        </div>
      )}
    </>
  )
}

export default Monitoring
