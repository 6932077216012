import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useEffect, useRef } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import ConfirmModal from "../../UI/ConfirmModal/ConfirmModal"

const DeleteAction = (props) => {
  const [openModal, setopenModal] = useState(false)
  const [elementToDelete, setelementToDelete] = useState(null)

  const deleteElement = () => {
    props.delete(elementToDelete)
    closeModal()
  }

  const hideModal = () => {
    setopenModal(false)
    setelementToDelete(null)
  }

  const closeModal = () => {
    setopenModal(false)
    setelementToDelete(null)
  }

  return (
    <>
      <ConfirmModal
        bsSize="medium"
        show={openModal}
        onHide={hideModal}
        onClose={closeModal}
        title={`Delete ${props.type[0].toUpperCase()}${props.type.slice(1)}`}
        className="text-center"
        closeText="Close"
        onAccept={deleteElement}
      >
        <div>
          <p>
            Are you sure you want to delete this {props.type[0].toUpperCase()}
            {props.type.slice(1)}?{" "}
          </p>
          <p>This action is permanent and cannot be undone.</p>
        </div>
      </ConfirmModal>
      <span className="actions-container inline-actions-container around" style={{ maxWidth: props.size, zIndex: 0 }}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip style={{ position: "absolute" }} id="1">
              Delete {props.type}
            </Tooltip>
          }
        >
          <span>
            <FontAwesomeIcon
              style={
                !props.element.hasOwnProperty("deleteable")
                  ? {}
                  : props.element.deleteable
                  ? {}
                  : { opacity: "0.4", cursor: "unset" }
              }
              icon={faTrashAlt}
              className="action-clickable"
              onClick={(e) => {
                props?.element?.deleteable && setelementToDelete(props.element)
                props?.element?.deleteable && setopenModal(true)
              }}
            ></FontAwesomeIcon>
          </span>
        </OverlayTrigger>
      </span>
    </>
  )
}

export default DeleteAction
