import React, { createContext, useContext, useState, useEffect } from 'react'
import { useSnackbar } from './snackbarContext'

const Config = createContext()
const UpdateConfig = createContext()

const useConfig = () => {
  return useContext(Config)
}

const useUpdateConfig = () => {
  return useContext(UpdateConfig)
}

const ConfigProvider = ({ children }) => {
  const [configInfo, setConfigInfo] = useState(null)
  const [loading, setloading] = useState(true)
  const [namespace, setnamespace] = useState('')

  const snackbar = useSnackbar()

  useEffect(() => {
    getNamespaceFile().then((data) => setnamespace(data))
  }, [])

  const getNamespaceFile = async () => {
    const data = await fetch('namespace')
      .then((data) => data)
      .catch((err) => console.error(err))

    if (data.status !== 200) {
      snackbar.setSnackbarInfo({
        status: 'error',
        message: '<b>An error occurred:</b> is your connection up?',
      })
      return 'ERROR'
    }

    const namespaceData = await data.text()
    if (namespaceData.toLowerCase().startsWith('<!doctype html>')) {
      snackbar.setSnackbarInfo({
        status: 'error',
        message: '<b>An error occurred:</b> Are you sure you created the <i>namespace</i> file?',
      })
      return 'ERROR'
    }

    if (namespaceData === '') {
      snackbar.setSnackbarInfo({
        status: 'warning',
        message: `<b>Warning!</b> You forgot to set the content of the <i>namespace</i> file!`,
      })
      return 'NOT SET'
    }

    return namespaceData
  }

  return (
    <Config.Provider
      value={{
        configInfo,
        setConfigInfo,
        loading,
        setloading,
        namespace,
      }}
    >
      <UpdateConfig.Provider
        value={{
          configInfo,
          setConfigInfo,
          loading,
          setloading,
        }}
      >
        {children}
      </UpdateConfig.Provider>
    </Config.Provider>
  )
}

export { ConfigProvider, useConfig, useUpdateConfig }
