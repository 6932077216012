import React, { useEffect, useState, useRef } from "react"
import Button from "react-bootstrap/Button"
import {
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExport, faFileImport, faClose } from "@fortawesome/free-solid-svg-icons"

import "./Homepage.less"
import { useConfig } from "../../contexts/configContext"
import { useSnackbar } from "../../contexts/snackbarContext"
import { isNonEmptyArray, isNonEmptyNumber, isNonEmptyObject, isNonEmptyString } from "../../utils"
import useHttpApi from "../useHttpApi"
import axios from "axios"
import CrossedInput from "../SharedComponents/CrossedInput"
import ConfirmModal from "../UI/ConfirmModal/ConfirmModal"

function Homepage(props) {
  const noFormErrors = {
    cipv4Addr: false,
    cport: false,
    pipv4Addr: false,
    pport: false,
    csecurity: false,
    psecurity: false,
  }
  const [cAdministrativeState, setcAdministrativeState] = useState(null)
  const [pAdministrativeState, setpAdministrativeState] = useState(null)
  const [producer, setproducer] = useState(null)
  const [consumer, setconsumer] = useState(null)
  const [formErrors, setformErrors] = useState(noFormErrors)
  const [scpisAvailable, setscpisAvailable] = useState(false)
  const [scpisEmbedded, setscpisEmbedded] = useState(false)
  const [scp, setscp] = useState(null)
  const [nrfisAvailable, setnrfisAvailable] = useState(false)
  const [nrfButtonsDisabled, setnrfButtonsDisabled] = useState(true)
  const [nrf, setnrf] = useState(null)
  const [vasProducer, setvasProducer] = useState(null)
  const [isSaveDisabled, setisSaveDisabled] = useState(false)
  const [ispvasDataPresent, setispvasDataPresent] = useState(false)
  const [iscvasDataPresent, setiscvasDataPresent] = useState(false)
  const [openConfirmModal, setopenConfirmModal] = useState(false)
  const [nonMandaryData, setnonMandaryData] = useState(null)

  const config = useConfig()
  const snackbar = useSnackbar()
  const cvasInputRef = useRef(null)
  const pvasInputRef = useRef(null)
  const namespace = config.namespace

  const psepp = "pSEPP"
  const csepp = "cSEPP"

  const admittedIpChars = /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/gm
  const admittedPortChars = /\d{1,4}/gm

  const httpApi = useHttpApi()

  useEffect(() => {
    props.setBreadcrumb("", "Homepage", "")
  }, [])

  const createRedisKey = (key, data) => {
    httpApi.callPostAPI({ url: key, data, snack: { show: false } })
  }
  //Keys creation, if needed

  const needToCreateRedisKey = () => {
    return Promise.all([
      httpApi.callGetAPI({
        url: "json/MOIattributes",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/MOIattributes", [])
          return "MOIattributes"
        },
        successCallback: (data) => 1,
      }),
      httpApi.callGetAPI({
        url: "json/cvas_rules",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/cvas_rules", {})
          return "cvas_rules"
        },
        successCallback: (data) => {
          isNonEmptyObject(data) ? setiscvasDataPresent(true) : setiscvasDataPresent(false)
          return 1
        },
      }),
      httpApi.callGetAPI({
        url: "json/pvas_rules",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/pvas_rules", {})
          return "pvas_rules"
        },
        successCallback: (data) => {
          isNonEmptyObject(data) ? setispvasDataPresent(true) : setispvasDataPresent(false)
          return 1
        },
      }),
      httpApi.callGetAPI({
        url: "json/pvas_next_hop",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/pvas_next_hop", {})
          return "pvas_next_hop"
        },
        successCallback: (data) => {
          if (isNonEmptyObject(data)) setvasProducer(data)
          return 1
        },
      }),
      httpApi.callGetAPI({
        url: "json/nrf-configuration",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/nrf-configuration", {})
          return "nrf-configuration"
        },
        successCallback: (data) => 1,
      }),
      httpApi.callGetAPI({
        url: "json/scp-configuration",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/scp-configuration", {})
          return "scp-configuration"
        },
        successCallback: (data) => 1,
      }),
      httpApi.callGetAPI({
        url: "json/scp-embedded",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/scp-embedded", { enabled: false })
          return "scp-embedded"
        },
        successCallback: (data) => 1,
      }),
    ])
  }

  useEffect(() => {
    needToCreateRedisKey().then((data) => {
      console.log(data)
      if (data.filter((el) => el !== 1).length > 0)
        snackbar.setSnackbarInfo({
          status: "warning",
          message: `The following requested <b>Redis keys</b> are missing and will be created: <i>${data.join(
            ", "
          )}</i>`,
        })
    })
  }, [])

  //Getting data for Consumer/Producer side details
  useEffect(() => {
    getMOIAttributes().then((_moiAttributes) => {
      if (_moiAttributes === -1) return
      if (_moiAttributes.length > 0) {
        computeConsumerAndProducer(_moiAttributes)
      }
    })
    getSCPConfiguration().then((data) => {
      const { BaseAddress, ..._data } = data

      isNonEmptyObject(_data) ? setscpisAvailable(true) : setscpisAvailable(false)
      setscp(_data || null)
    })
    getExternalNRF().then((data) => {
      if (data) {
        const { BaseAddress, ..._data } = data
        const nonEmptyValues = Object.values(_data).find((el) => isNonEmptyString(el))

        nonEmptyValues ? setnrfisAvailable(true) : setnrfisAvailable(false)
        nonEmptyValues ? setnrfButtonsDisabled(false) : setnrfButtonsDisabled(true)
        setnrf(_data || null)
      }

      // if (
      //   (isNonEmptyObject(data) && isNonEmptyString(data.url)) ||
      //   (isNonEmptyString(data.ipv4Addr) && isNonEmptyString(data.port))
      // ) {
      //   setnrfButtonsDisabled(false)
      //   setnrfisAvailable(true)
      // } else {
      //   setnrfButtonsDisabled(true)
      //   setnrfisAvailable(false)
      // }
      // setnrf(data || null)
    })
    getEmbeddedSCP().then((data) => {
      setscpisEmbedded(data)
    })
  }, [])

  const getEmbeddedSCP = async () => {
    return await httpApi.callGetAPI({ url: "json/scp-embedded", snack: { show: false } })
  }

  const getMOIAttributes = async () => {
    const res = await httpApi.callGetAPI({
      url: "json/MOIattributes",
      successCallback: (data) => data || [],
      errorCallback: (err) => -1,
      snack: {
        show: true,
        error: {
          status: "info",
          message:
            "Is it your first time here? Please set all the fields below and press the <b>SAVE</b> button in order to configure your <i>SEPP</i>.",
        },
      },
    })
    return res
  }

  const getSCPConfiguration = async () => {
    const res = await httpApi.callGetAPI({
      url: "json/scp-configuration",
      successCallback: (data) => data || [],
      errorCallback: (err) => -1,
      snack: {
        show: true,
        warning: {
          status: "warning",
          message: "No <b>SCP</b> was found for this <i>SEPP</i>.",
        },
      },
    })
    return res
  }

  const getExternalNRF = async () => {
    const res = await httpApi.callGetAPI({
      url: "json/nrf-configuration",
      successCallback: (data) => {
        return data || []
      },
      errorCallback: (err) => -1,
      snack: {
        show: true,
        warning: {
          status: "warning",
          message: "No <b>NRF</b> was found for this <i>SEPP</i>.",
        },
      },
    })
    return res
  }

  const computeConsumerAndProducer = (_moiAttributes) => {
    const _producer = _moiAttributes.find((ma) => ma.sEPPType === psepp)
    const _consumer = _moiAttributes.find((ma) => ma.sEPPType === csepp)

    if (!_producer)
      setproducer({
        administrativeState: "LOCKED",
        operationalState: "DISABLED",
      })
    else setproducer(_producer)

    if (!_consumer)
      setconsumer({
        administrativeState: "LOCKED",
        operationalState: "DISABLED",
      })
    else setconsumer(_consumer)
  }

  const publishData = () => {
    const firstTimeData = [
      {
        administrativeState: "LOCKED",
        operationalState: "DISABLED",
        fqdn: config.configInfo.dnsName,
        pLMNId: config.configInfo.plmnid,
        sEPPType: psepp,
        id: `${namespace}-${psepp}`,
        sEPPId: `2${namespace.split("-").find((el) => el.match(/\d+/gm))}`,
        objectClass: "SEPP",
        ipv4Addr: "4.4.4.4",
        port: "4444",
        href: window.location.href,
        security: ["TLS"],
      },
      {
        administrativeState: "LOCKED",
        operationalState: "DISABLED",
        fqdn: config.configInfo.dnsName,
        pLMNId: config.configInfo.plmnid,
        sEPPType: csepp,
        id: `${namespace}-${csepp}`,
        sEPPId: `1${namespace.split("-").find((el) => el.match(/\d+/gm))}`,
        objectClass: "SEPP",
        ipv4Addr: "2.2.2.2",
        port: "2222",
        href: window.location.href,
        security: ["TLS"],
      },
    ]

    httpApi.callPostAPI({
      url: "json/MOIattributes",
      data: firstTimeData,
      snack: {
        show: true,
        success: {
          status: "success",
          message: "Data correctly saved",
        },
      },
    })
  }

  const checkForFormErrors = () => {
    let isError = false
    if (!isNonEmptyString(consumer.ipv4Addr)) {
      setformErrors((prev) => ({ ...prev, cipv4Addr: true }))
      isError = true
    } else {
      setformErrors((prev) => ({ ...prev, cipv4Addr: false }))
    }
    if (!isNonEmptyString(consumer.port)) {
      setformErrors((prev) => ({ ...prev, cport: true }))
      isError = true
    } else {
      setformErrors((prev) => ({ ...prev, cport: false }))
    }
    if (!isNonEmptyString(producer.ipv4Addr)) {
      setformErrors((prev) => ({ ...prev, pipv4Addr: true }))
      isError = true
    } else {
      setformErrors((prev) => ({ ...prev, pipv4Addr: false }))
    }
    if (!isNonEmptyString(producer.port)) {
      setformErrors((prev) => ({ ...prev, pport: true }))
      isError = true
    } else {
      setformErrors((prev) => ({ ...prev, pport: false }))
    }
    if (isNonEmptyArray(consumer.security)) {
      setformErrors((prev) => ({ ...prev, csecurity: true }))
      isError = true
    } else {
      setformErrors((prev) => ({ ...prev, csecurity: false }))
    }
    if (isNonEmptyArray(producer.security)) {
      setformErrors((prev) => ({ ...prev, psecurity: true }))
      isError = true
    } else {
      setformErrors((prev) => ({ ...prev, psecurity: false }))
    }

    if (isError) {
      snackbar.setSnackbarInfo({
        status: "error",
        message: "Please, fill all the red fields before saving.",
      })
      return -1
    }

    setformErrors(noFormErrors)
    return 0
  }

  const saveHomepageData = async () => {
    // if (checkForFormErrors() === -1) return

    const _producer = buildProducer()
    const _consumer = buildConsumer()

    //uncomment those two lines to push the homepage data for the first time or when something changes inside the json, then press Save
    // publishData();
    // return;

    await httpApi.callPostAPI({
      url: "json/MOIattributes",
      data: [_producer, _consumer],
      snack: {
        show: true,
        success: {
          status: "success",
          message: "Data successfully saved!",
        },
      },
    })

    getMOIAttributes().then((_moiAttributes) => {
      if (_moiAttributes === -1) return
      computeConsumerAndProducer(_moiAttributes)
    })

    nrf.BaseAddress = isNonEmptyString(nrf.url) ? nrf.url : `${nrf.ipv4Addr || ""}:${nrf.port || ""}`
    scp.BaseAddress = isNonEmptyString(scp.url) ? scp.url : `${scp.ipv4Addr || ""}:${scp.port || ""}`
    nrf.BaseAddress === ":" && (nrf.BaseAddress = "")
    scp.BaseAddress === ":" && (scp.BaseAddress = "")

    await httpApi.callPostAPI({
      url: "json/nrf-configuration",
      data: nrf || {},
      snack: { show: true },
      successCallback: async () => {
        await httpApi.callGetAPI({ url: "json/nrf-configuration", snack: { show: false } }).then((data) => {
          setnrf(data)
          if (
            (isNonEmptyObject(data) && isNonEmptyString(data.url)) ||
            (isNonEmptyString(data.ipv4Addr) && isNonEmptyString(data.port))
          )
            setnrfButtonsDisabled(false)
          else setnrfButtonsDisabled(true)
        })
      },
    })
    await httpApi.callPostAPI({
      url: "json/scp-configuration",
      data: scp || {},
      snack: { show: true },
      successCallback: async () => {
        await httpApi.callGetAPI({ url: "json/scp-configuration", snack: { show: false } }).then((data) => setscp(data))
      },
    })
    await httpApi.callPostAPI({
      url: "json/embedded-scp",
      data: scpisEmbedded,
      snack: { show: true },
      successCallback: async () => {
        await httpApi
          .callGetAPI({ url: "json/embedded-scp", snack: { show: false } })
          .then((data) => setscpisEmbedded(data))
      },
    })

    sendVasData()
  }

  //For saving next hop data
  const sendVasData = async () => {
    await httpApi.callPostAPI({
      url: "json/pvas_next_hop",
      snack: { show: true },
      data: vasProducer || {},
    })
  }

  const buildProducer = () => {
    return {
      administrativeState: pAdministrativeState || producer.administrativeState || "LOCKED",
      operationalState: producer.operationalState || "DISABLED",
      fqdn: config.configInfo.dnsName,
      pLMNId: config.configInfo.plmnid,
      sEPPType: psepp,
      id: `${namespace}-${psepp}`,
      sEPPId: `2${namespace.split("-").find((el) => el.match(/\d+/gm))}`,
      objectClass: "SEPP",
      ipv4Addr: producer.ipv4Addr || "",
      port: producer.port || "",
      href: window.location.href,
      security: producer.security || ["TLS"],
    }
  }

  const buildConsumer = () => {
    return {
      administrativeState: cAdministrativeState || consumer.administrativeState || "LOCKED",
      operationalState: consumer.operationalState || "DISABLED",
      fqdn: config.configInfo.dnsName,
      pLMNId: config.configInfo.plmnid,
      sEPPType: csepp,
      id: `${namespace}-${csepp}`,
      sEPPId: `1${namespace.split("-").find((el) => el.match(/\d+/gm))}`,
      objectClass: "SEPP",
      ipv4Addr: consumer.ipv4Addr || "",
      port: consumer.port || "",
      href: window.location.href,
      security: consumer.security || ["TLS"],
    }
  }

  const checkSecurity = (e, prosumer, value) => {
    let tmp = { ...prosumer }
    if (!tmp.security) tmp.security = []
    if (e.target.checked && !tmp.security.includes(value)) tmp.security.push(value)
    else tmp.security = tmp.security.filter((item) => item !== value)
    return tmp
  }

  const importFile = (vasRef) => {
    vasRef.current.click()
  }

  const handleFileUploadEvent = (e, vasType) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = (e) => uploadVasFile(e.target.result, vasType, file)
      e.target.value = ""
    }
  }

  const uploadVasFile = async (fileContent, vasType, file) => {
    let formData = new FormData()
    formData.append("file", file)

    await httpApi.callUploadAPI({
      url: `${vasType === "cvas" ? "file/cvas_rules" : "file/pvas_rules"}`,
      formData,
      snack: { show: true, success: { status: "success", message: `Your file has successfully been uploaded` } },
      successCallback: () => {
        if (vasType === "cvas") {
          isNonEmptyObject(JSON.parse(fileContent)) ? setiscvasDataPresent(true) : setiscvasDataPresent(false)
        }
        if (vasType === "pvas") {
          isNonEmptyObject(JSON.parse(fileContent)) ? setispvasDataPresent(true) : setispvasDataPresent(false)
        }
      },
    })
  }

  const downloadVasFile = async (vasType) => {
    const fileName = `${vasType === "cvas" ? "cvas_rules" : "pvas_rules"}`
    const url = `${vasType === "cvas" ? "file/cvas_rules" : "file/pvas_rules"}`
    await httpApi.callDownloadAPI(
      {
        url,
        snack: { show: true, success: { status: "success", message: "Vas file successfully exported" } },
      },
      { name: fileName, type: "json" }
    )
  }

  const sendMessage = () => {}

  const discovery = async () => {
    await axios({
      method: "post",
      url: "discover",
      baseURL: `${config.configInfo.agentUrl}`,
    })
      .then((data) => {
        snackbar.setSnackbarInfo({ status: "success", message: "Discovery correctly sent!" })
      })
      .catch((err) => {
        snackbar.setSnackbarInfo({ status: "error", message: `Error: ${err}` })
      })
  }

  const register = async () => {
    //se due click vengono fatti in un intervallo di 10 secondi, ottengo un errore "too many request"
    await axios({
      method: "post",
      url: "register",
      baseURL: `${config.configInfo.agentUrl}`,
    })
      .then((data) => {
        snackbar.setSnackbarInfo({ status: "success", message: "Register correctly sent!" })
      })
      .catch((err) => {
        snackbar.setSnackbarInfo({ status: "error", message: `Error: ${err}` })
      })
  }

  const handleInput = (e, obj, field) => {
    if (obj === "producer") {
      const _producer = { ...producer }
      _producer[field] = e.target.value
      setproducer(_producer)
    }
    if (obj === "consumer") {
      const _consumer = { ...consumer }
      _consumer[field] = e.target.value
      setconsumer(_consumer)
    }
    if (obj === "vasProducer") {
      const _vasProducer = { ...vasProducer }
      _vasProducer[field] = e.target.value
      setvasProducer(_vasProducer)
    }
    if (obj === "scp") {
      const _scp = { ...scp }
      if (field === "ipv4Addr") {
        const test = e.target.value.match(/\d|\./g)
        _scp[field] = test != null && test.length > 0 ? test.join("") : null
        _scp.url = ""
      }
      if (field === "port") {
        const test = e.target.value.match(/\d/g)
        _scp[field] = test != null && test.length > 0 ? test.join("") : null
        _scp.url = ""
      }
      if (field === "url") {
        _scp.url = e.target.value
        _scp.ipv4Addr = ""
        _scp.port = ""
      }
      setscp(_scp)
    }
    if (obj === "nrf") {
      const _nrf = { ...nrf }
      if (field === "ipv4Addr") {
        const test = e.target.value.match(/\d|\./g)
        _nrf[field] = test != null && test.length > 0 ? test.join("") : null
        _nrf.url = ""
      }
      if (field === "port") {
        const test = e.target.value.match(/\d/g)
        _nrf[field] = test != null && test.length > 0 ? test.join("") : null
        _nrf.url = ""
      } else {
        _nrf.url = e.target.value
        _nrf.ipv4Addr = ""
        _nrf.port = ""
      }
      setnrf(_nrf)
    }
  }

  useEffect(() => {
    checkRequiredFields()
  }, [consumer, producer])

  const checkRequiredFields = () => {
    setisSaveDisabled(
      !isNonEmptyString(consumer?.ipv4Addr) ||
        !isNonEmptyString(consumer?.port) ||
        !isNonEmptyString(producer?.ipv4Addr) ||
        !isNonEmptyString(producer?.port)
    )
  }

  const checkNonMandaryData = () => {
    const data = [
      ispvasDataPresent ? null : "Producer VAS rules",
      iscvasDataPresent ? null : "Consumer VAS rules",
      scpisAvailable ? null : "SCP configuration",
      nrfisAvailable ? null : "NRF configuration",
    ].filter((el) => el)

    if (data.length === 0) saveHomepageData()
    else {
      setnonMandaryData(data)
      setopenConfirmModal(true)
    }
  }

  return (
    <>
      <ConfirmModal
        bsSize="medium"
        show={openConfirmModal}
        onHide={() => setopenConfirmModal(false)}
        onClose={() => setopenConfirmModal(false)}
        title="Warning!"
        className="text-center"
        onAccept={(e) => {
          setopenConfirmModal(false)
          saveHomepageData()
        }}
        closeText="Close"
      >
        <div style={{ textAlign: "left" }}>
          <p>The following data have not been set yet:</p>
          {isNonEmptyArray(nonMandaryData) &&
            nonMandaryData.map((el) => (
              <p style={{ fontSize: "10pt", textTransform: "uppercase", fontWeight: "bold" }}>{` - ${el}`}</p>
            ))}
          <p>Do you want to continue anyway?</p>
        </div>
      </ConfirmModal>

      <Form>
        <div className="page">
          <Tabs defaultActiveKey="Details" id="seep-tabs" className="mb-3" justify>
            <Tab eventKey="Details" title="DETAILS">
              <h5 className="title mt-5">Sepp Details</h5>
              <div className="data-row">
                <span>Sepp ID:</span>
                <span
                  style={{
                    fontSize: "11pt",
                    ...(namespace === "ERROR" ? { color: "red" } : namespace === "NOT SET" ? { color: "orange" } : {}),
                  }}
                >
                  {namespace}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>PLMNID:</span>
                <span
                  style={{
                    ...(namespace === "ERROR" ? { color: "red" } : namespace === "NOT SET" ? { color: "orange" } : {}),
                  }}
                >
                  {`MCC:${config.configInfo.plmnid.split("-")[0]} MNC:${config.configInfo.plmnid.split("-")[1]}`}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Domain:</span>
                <span
                  style={
                    config.configInfo.dnsName == null || config.configInfo.dnsName === "" ? { color: "orange" } : {}
                  }
                >
                  {config.configInfo.dnsName || "NOT SET"}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Monitoring Component:</span>
                <span style={isNonEmptyString(config.configInfo.monitoringpath) ? {} : { color: "orange" }}>
                  {/* {config.configInfo.monitoringpath || "NOT SET"} */}
                  https://snsm-latest-new.5gef.net/eventListener
                </span>
              </div>
              <div className="separator"></div>
            </Tab>

            <Tab
              eventKey="Consumer"
              title={
                <div className="rowDiv j-center">
                  <span>CONSUMER</span>
                  {(!isNonEmptyString(consumer?.ipv4Addr) || !isNonEmptyString(consumer?.port)) && (
                    <span className="mandatory-tab"></span>
                  )}
                </div>
              }
            >
              <h5 className="title mt-5">Consumer Side Details</h5>
              <div className="data-row">
                <div>
                  <span>IP Address:</span>
                  <span className="mandatory-field"> *</span>
                </div>
                <CrossedInput
                  size="40%"
                  object={consumer}
                  value={consumer?.ipv4Addr || ""}
                  placeholder="192.168.1.1"
                  callback={(e) => handleInput(e, "consumer", "ipv4Addr")}
                />
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <div>
                  <span>Port:</span>
                  <span className="mandatory-field"> *</span>
                </div>
                <CrossedInput
                  size="40%"
                  object={consumer}
                  value={consumer?.port || ""}
                  placeholder="1111"
                  callback={(e) => handleInput(e, "consumer", "port")}
                />
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Administrative State:</span>
                <DropdownButton
                  as={ButtonGroup}
                  key="primary"
                  id={`dropdown-variants-primary`}
                  title={cAdministrativeState || consumer?.administrativeState || "Empty"}
                  onSelect={(value) => setcAdministrativeState(value)}
                >
                  <Dropdown.Item active={cAdministrativeState === "LOCKED"} eventKey="LOCKED">
                    LOCKED
                  </Dropdown.Item>
                  <Dropdown.Item active={cAdministrativeState === "UNLOCKED"} eventKey="UNLOCKED">
                    UNLOCKED
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Operational State:</span>
                <span
                  style={
                    consumer?.operationalState != null && consumer?.operationalState !== "" ? {} : { color: "orange" }
                  }
                >
                  {consumer?.operationalState != null && consumer?.operationalState !== ""
                    ? consumer.operationalState
                    : "NOT SET"}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Security Capabilities:</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Form.Check
                    inline
                    type="checkbox"
                    id="TLS-C"
                    label="TLS"
                    checked={consumer?.security?.includes("TLS") || false}
                    disabled
                    onChange={(e) => {
                      setconsumer(checkSecurity(e, consumer, "TLS"))
                    }}
                  ></Form.Check>
                  <Form.Check
                    style={{ marginRight: "0px" }}
                    inline
                    type="checkbox"
                    id="PRINS-C"
                    label="PRINS"
                    checked={consumer?.security?.includes("PRINS") || false}
                    onChange={(e) => {
                      setconsumer(checkSecurity(e, consumer, "PRINS"))
                    }}
                    disabled
                  ></Form.Check>
                </div>
              </div>
              <div className="separator"></div>
            </Tab>

            <Tab
              eventKey="Producer"
              title={
                <div className="rowDiv j-center">
                  <span>PRODUCER</span>
                  {(!isNonEmptyString(producer?.ipv4Addr) || !isNonEmptyString(producer?.port)) && (
                    <span className="mandatory-tab"></span>
                  )}
                </div>
              }
            >
              <h5 className="title mt-5">Producer Side Details</h5>
              <div className="data-row">
                <div>
                  <span>IP Address:</span>
                  <span className="mandatory-field"> *</span>
                </div>
                <CrossedInput
                  size="40%"
                  object={producer}
                  value={producer?.ipv4Addr || ""}
                  placeholder="192.168.1.1"
                  callback={(e) => handleInput(e, "producer", "ipv4Addr")}
                />
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <div>
                  <span>Port:</span>
                  <span className="mandatory-field"> *</span>
                </div>
                <CrossedInput
                  size="40%"
                  object={producer}
                  value={producer?.port || ""}
                  placeholder="1111"
                  callback={(e) => handleInput(e, "producer", "port")}
                />
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Administrative State:</span>
                <DropdownButton
                  as={ButtonGroup}
                  key="primary"
                  id={`dropdown-variants-primary`}
                  variant="primary"
                  title={pAdministrativeState || producer?.administrativeState || "Empty"}
                  onSelect={(value) => setpAdministrativeState(value)}
                >
                  <Dropdown.Item active={pAdministrativeState === "LOCKED"} eventKey="LOCKED">
                    LOCKED
                  </Dropdown.Item>
                  <Dropdown.Item active={pAdministrativeState === "UNLOCKED"} eventKey="UNLOCKED">
                    UNLOCKED
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Operational State:</span>
                <span
                  style={
                    producer?.operationalState != null && producer?.operationalState !== "" ? {} : { color: "orange" }
                  }
                >
                  {producer?.operationalState != null && producer?.operationalState !== ""
                    ? producer.operationalState
                    : "NOT SET"}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Security Capabilities:</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Form.Check
                    inline
                    type="checkbox"
                    id="TLS-C"
                    label="TLS"
                    checked={producer?.security?.includes("TLS") || false}
                    disabled
                    onChange={(e) => {
                      setproducer(checkSecurity(e, producer, "TLS"))
                    }}
                  ></Form.Check>
                  <Form.Check
                    style={{ marginRight: "0px" }}
                    inline
                    type="checkbox"
                    id="PRINS-C"
                    label="PRINS"
                    checked={producer?.security?.includes("PRINS") || false}
                    onChange={(e) => {
                      setproducer(checkSecurity(e, producer, "PRINS"))
                    }}
                    disabled
                  ></Form.Check>
                </div>
              </div>
              <div className="separator"></div>
            </Tab>

            <Tab eventKey="vas-rules" title="VAS RULES">
              <h5 className="title mt-5">VAS Rules</h5>
              <div className="data-row">
                <span>Consumer VAS rules</span>
                <div className="rowDiv j-start">
                  <div
                    onClick={() => importFile(cvasInputRef)}
                    style={{
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>Upload</span>
                    <input
                      ref={cvasInputRef}
                      type="file"
                      accept="application/JSON"
                      id="cvasUpload"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileUploadEvent(e, "cvas")
                      }}
                    />
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip style={{ position: "absolute" }}>Import CVAS file</Tooltip>}
                    >
                      <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
                    </OverlayTrigger>
                  </div>
                  <div
                    onClick={() => {
                      downloadVasFile("cvas")
                    }}
                    style={{
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>Download</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip style={{ position: "absolute" }}>Export CVAS file</Tooltip>}
                    >
                      <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Producer VAS rules</span>
                <div className="rowDiv j-start">
                  <div
                    onClick={() => importFile(pvasInputRef)}
                    style={{
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>Upload</span>
                    <input
                      ref={pvasInputRef}
                      type="file"
                      accept="application/JSON"
                      id="cvasUpload"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileUploadEvent(e, "pvas")
                      }}
                    />
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip style={{ position: "absolute" }}>Import PVAS file</Tooltip>}
                    >
                      <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
                    </OverlayTrigger>
                  </div>
                  <div
                    onClick={() => {
                      downloadVasFile("pvas")
                    }}
                    style={{
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>Download</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip style={{ position: "absolute" }}>Export PVAS file</Tooltip>}
                    >
                      <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className="separator"></div>

              {/* <div className="data-row">
              <span>IP Address:</span>
              <CrossedInput
                size="40%"
                object={vasProducer}
                value={vasProducer?.ipv4Addr || ""}
                placeholder="192.168.1.1"
                callback={(e) => handleInput(e, "vasProducer", "ipv4Addr")}
              />
            </div>
            <div className="separator"></div>

            <div className="data-row">
              <span>Port:</span>
              <CrossedInput
                size="40%"
                object={vasProducer}
                value={vasProducer?.port || ""}
                placeholder="1111"
                callback={(e) => handleInput(e, "vasProducer", "port")}
              />
            </div>
            <div className="separator"></div> */}
            </Tab>

            <Tab eventKey="scp-configuration" title="SCP CONFIGURATION">
              <h5 className="title mt-5">SCP Configuration</h5>
              <div className="data-row">
                <span>Is Embedded</span>
                <div className="rowDiv j-around">
                  <span style={{ marginTop: "8px", marginRight: "5px" }}>NO</span>
                  <Form.Check
                    type="switch"
                    checked={scpisEmbedded?.enabled || false}
                    onChange={(e) => setscpisEmbedded({ enabled: e.target.checked })}
                  />
                  <span style={{ marginTop: "8px", marginLeft: "5px" }}>YES</span>
                </div>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Is Available</span>
                <div className="rowDiv j-around">
                  <span style={{ marginTop: "8px", marginRight: "5px" }}>NO</span>
                  <Form.Check
                    style={{ marginTop: "7px" }}
                    type="switch"
                    checked={scpisAvailable || false}
                    onChange={(e) => {
                      if (!e.target.checked) setscp({})
                      setscpisAvailable(e.target.checked)
                    }}
                  />
                  <span style={{ marginTop: "8px", marginLeft: "5px" }}>YES</span>
                </div>
              </div>
              <div className="separator"></div>

              {scpisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>IP Address</span>
                    <CrossedInput
                      size="40%"
                      object={scp}
                      value={scp?.ipv4Addr || ""}
                      placeholder="192.168.1.1"
                      clearDisabled={isNonEmptyString(scp?.url)}
                      inputDisabled={isNonEmptyString(scp?.url)}
                      callback={(e) => handleInput(e, "scp", "ipv4Addr")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {scpisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Port</span>
                    <CrossedInput
                      size="40%"
                      object={scp}
                      value={scp?.port || ""}
                      placeholder="1111"
                      clearDisabled={isNonEmptyString(scp?.url)}
                      inputDisabled={isNonEmptyString(scp?.url)}
                      callback={(e) => handleInput(e, "scp", "port")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {scpisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Url</span>

                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: "absolute" }}>Must begin by 'http://' and end by '/'</Tooltip>
                      }
                    >
                      <CrossedInput
                        size="40%"
                        object={scp}
                        value={scp?.url || ""}
                        placeholder="http:example.com/"
                        clearDisabled
                        inputDisabled
                        // clearDisabled={isNonEmptyString(scp?.ipv4Addr) || isNonEmptyString(scp?.port)}
                        // inputDisabled={isNonEmptyString(scp?.ipv4Addr) || isNonEmptyString(scp?.port)}
                        callback={(e) => handleInput(e, "scp", "url")}
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}
            </Tab>

            <Tab eventKey="nrf-configuration" title="NRF CONFIGURATION">
              <h5 className="title mt-5">NRF Configuration</h5>

              <div className="data-row">
                <span>Is Available</span>
                <div className="rowDiv j-around">
                  <span style={{ marginTop: "8px", marginRight: "5px" }}>NO</span>
                  <Form.Check
                    style={{ marginTop: "7px" }}
                    type="switch"
                    checked={nrfisAvailable || false}
                    onChange={(e) => {
                      if (!e.target.checked) setnrf({})
                      setnrfisAvailable(e.target.checked)
                    }}
                  />
                  <span style={{ marginTop: "8px", marginLeft: "5px" }}>YES</span>
                </div>
              </div>
              <div className="separator"></div>

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>IP Address</span>
                    <CrossedInput
                      size="40%"
                      object={nrf}
                      value={nrf?.ipv4Addr || ""}
                      placeholder="192.168.1.1"
                      clearDisabled
                      inputDisabled
                      // clearDisabled={isNonEmptyString(nrf?.url)}
                      // inputDisabled={isNonEmptyString(nrf?.url)}
                      callback={(e) => handleInput(e, "nrf", "ipv4Addr")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Port</span>
                    <CrossedInput
                      size="40%"
                      object={nrf}
                      value={nrf?.port || ""}
                      placeholder="1111"
                      clearDisabled
                      inputDisabled
                      // clearDisabled={isNonEmptyString(nrf?.url)}
                      // inputDisabled={isNonEmptyString(nrf?.url)}
                      callback={(e) => handleInput(e, "nrf", "port")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Url</span>
                    <CrossedInput
                      size="40%"
                      object={nrf}
                      value={nrf?.url || ""}
                      placeholder="http:example.com/"
                      clearDisabled={isNonEmptyString(nrf?.ipv4Addr) || isNonEmptyString(nrf?.port)}
                      inputDisabled={isNonEmptyString(nrf?.ipv4Addr) || isNonEmptyString(nrf?.port)}
                      callback={(e) => handleInput(e, "nrf", "url")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Register</span>
                    <Button
                      disabled={nrfButtonsDisabled}
                      style={{
                        width: "40%",
                        ...(nrfButtonsDisabled && { backgroundColor: "#acb0b5", borderColor: "#8c8c8c" }),
                      }}
                      onClick={() => register()}
                    >
                      Register
                    </Button>
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Discover</span>
                    <Button
                      style={{
                        width: "40%",
                        marginTop: "10px",
                        // ...(nrfButtonsDisabled && { backgroundColor: "#acb0b5", borderColor: "#8c8c8c" }),
                      }}
                      onClick={() => discovery()}
                    >
                      Discovery
                    </Button>
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}
            </Tab>
            {/* <Tab eventKey="Dashboard" title="DASHBOARD">
              <h5 className="title mt-5">Dashboard</h5>

              <iframe
                src={`https://sepp-latest.5gef.net/grafana/d/e2c25567-d073-46fb-8ac5-e0e56fe36883/sepp-test?orgId=1&var-InstanceName=${namespace.replaceAll(
                  "-",
                  "_"
                )}&var-namespace=${namespace}&kiosk`}
                width="90%"
                height="650px"
              ></iframe>
            </Tab> */}
          </Tabs>
          <Button
            disabled={isSaveDisabled}
            style={{ width: "10%", float: "right", marginRight: "1.5%" }}
            onClick={() => checkNonMandaryData()}
          >
            Save
          </Button>
        </div>

        {/* <iframe src="https://kubeview-7d9186ff2b824df983aa164c3371d32c.5gef.net/" width="500px" height="500px"></iframe> */}
      </Form>
    </>
  )
}

export default Homepage
