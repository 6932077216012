import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
// import registerServiceWorker from './registerServiceWorker';
import axios from 'axios'

const baseUrl =
  document.getElementsByTagName('base')[0].getAttribute('href') || ''

console.log(baseUrl)
const container = document.getElementById('root')
// Create a root.
const root = createRoot(container)

// const userLogged = localStorage.getItem('JWT_Token') ? true : false;
const userLogged = true
axios.defaults.headers.common['Authorization'] = userLogged
  ? 'Bearer ' + localStorage.getItem('JWT_Token')
  : null
root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
)

// registerServiceWorker();
