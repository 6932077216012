import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import FormGroup from "react-bootstrap/FormGroup"
import FormControl from "react-bootstrap/FormControl"
import FormSelect from "react-bootstrap/FormSelect"
import Form from "react-bootstrap/Form"
import "./Routing.less"
import { v4 as uuidv4 } from "uuid"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { isNonEmptyNumber, isNonEmptyString, isNonEmptyArray } from "../../utils"
import CrossedInput from "../SharedComponents/CrossedInput"

const AddDomainModal = (props) => {
  const emptyDomain = {
    domain_name: "",
    dns: "",
    is_hub: false,
    address_1: "",
    address_2: "",
    address_3: "",
    address_4: "",
    sni: "",
    id: uuidv4(),
    addresses: [],
    mode: 0,
    readableMode: "Round-Robin",
    deleteable: true,
  }
  const [formFilled, setformFilled] = useState(false)
  const [formErrors, setformErrors] = useState([])
  const [addressesError, setaddressesError] = useState({
    address_1: false,
    address_2: false,
    address_3: false,
    address_4: false,
  })
  const [domain, setdomain] = useState(emptyDomain)
  const addressMode = [
    { field: "Round-Robin", value: 0 },
    { field: "Primary/Secondary", value: 1 },
  ]
  const mandatoryFields = ["domain_name", "dns", "address_1", "sni"]
  const addressRegex = /https?:\/\/[a-zA-Z0-9.\-?=&_:]+\//g
  const admittedCharRegex = /[a-zA-Z0-9.\-?=&_:\/]/g

  // const addDomain = () => {
  //   props.addDomain(domain)
  //   props.onHide()
  // }

  useEffect(() => {
    if (formFilled) {
      props.addDomain(domain)
      props.onHide()
      setdomain(emptyDomain)
      setformFilled(false)
    }
  }, [formFilled])

  const checkMode = (tmpDomain) => {
    tmpDomain.mode = isNonEmptyNumber(tmpDomain.mode) ? tmpDomain.mode : 0
    return tmpDomain
  }

  const checkMandatoryFields = () => {
    const tmpDomain = { ...domain }
    const errors = new Set()
    Object.keys(tmpDomain).forEach((key) => {
      if (mandatoryFields.includes(key)) {
        if (key === "domain_name") {
          !isNonEmptyString(tmpDomain.domain_name) && errors.add(`Node Name cannot be empty`)
        }
        if (key === "dns") {
          props.domains.find((d) => d.dns === tmpDomain.dns) &&
            errors.add(`This Domain already exists. Please change it to a new one`)
          !isNonEmptyString(tmpDomain.dns) && errors.add(`Domain cannot be empty`)
        }
        if (key === "address_1") {
          !isNonEmptyString(tmpDomain.address_1) && errors.add(`The first Address field cannot be empty`)
          !addressRegex.test(tmpDomain.address_1) && errors.add(`You must provide a valide address`)
        }
        if (key === "sni") {
          !isNonEmptyString(tmpDomain.sni) && errors.add(`The SNI field cannot be empty`)
        }
      } else {
        if (key === "address_2" || key === "address_3" || key === "address_4") {
          if (isNonEmptyString(tmpDomain[key]))
            !addressRegex.test(tmpDomain[key]) && errors.add(`You must provide a valide address`)
        }
      }
    })
    if (Array.from(errors).length === 0) setformFilled(true)
    setformErrors(Array.from(errors))
  }

  // const checkMandatroyFields = () => {
  //   const tmpaddressesError = { ...addressesError }
  //   const expr = /^(https?:\/\/)(\S+)?\/$/gm
  //   domain.address_1 !== ""
  //     ? domain.address_1.match(expr)
  //       ? (tmpaddressesError.address_1 = false)
  //       : (tmpaddressesError.address_1 = true)
  //     : (tmpaddressesError.address_1 = false)
  //   domain.address_2 !== ""
  //     ? domain.address_2.match(expr)
  //       ? (tmpaddressesError.address_2 = false)
  //       : (tmpaddressesError.address_2 = true)
  //     : (tmpaddressesError.address_2 = false)
  //   domain.address_3 !== ""
  //     ? domain.address_3.match(expr)
  //       ? (tmpaddressesError.address_3 = false)
  //       : (tmpaddressesError.address_3 = true)
  //     : (tmpaddressesError.address_3 = false)
  //   domain.address_4 !== ""
  //     ? domain.address_4.match(expr)
  //       ? (tmpaddressesError.address_4 = false)
  //       : (tmpaddressesError.address_4 = true)
  //     : (tmpaddressesError.address_4 = false)

  //   const addressAreOk = Object.values(tmpaddressesError).every((addressError) => addressError === false)
  //   const sniIsOk = isNonEmptyString(domain.sni)

  //   let tmpDomain = { ...domain }
  //   tmpDomain.addresses = [tmpDomain.address_1, tmpDomain.address_2, tmpDomain.address_3, tmpDomain.address_4].filter(
  //     (d) => d !== ""
  //   )

  //   const isDomainNameOk = !props.domains.find((d) => d.domain_name === tmpDomain.domain_name)

  //   const allOk = addressAreOk && sniIsOk && isDomainNameOk

  //   tmpDomain = checkMode(tmpDomain)
  //   allOk && setdomain(tmpDomain)
  //   allOk && setformFilled(true)
  //   !allOk && setaddressesError(tmpaddressesError)
  // }

  const handleName = (e) => {
    const tmpDomain = { ...domain }
    tmpDomain.domain_name = e.target.value
    setdomain(tmpDomain)
  }

  const handleDns = (e) => {
    const tmpDomain = { ...domain }
    tmpDomain.dns = e.target.value
    setdomain(tmpDomain)
  }

  const handleIsHub = (e) => {
    const tmpDomain = { ...domain }
    tmpDomain.is_hub = e.target.checked
    setdomain(tmpDomain)
  }

  const handleaddress_1 = (e) => {
    const tmpDomain = { ...domain }
    tmpDomain.address_1 = e.target.value
    setdomain(tmpDomain)
  }

  const handleaddress_2 = (e) => {
    const tmpDomain = { ...domain }
    tmpDomain.address_2 = e.target.value
    setdomain(tmpDomain)
  }

  const handleaddress_3 = (e) => {
    const tmpDomain = { ...domain }
    tmpDomain.address_3 = e.target.value
    setdomain(tmpDomain)
  }

  const handleaddress_4 = (e) => {
    const tmpDomain = { ...domain }
    tmpDomain.address_4 = e.target.value
    setdomain(tmpDomain)
  }

  const handleSni = (e) => {
    const tmpDomain = { ...domain }
    tmpDomain.sni = e.target.value
    setdomain(tmpDomain)
  }

  const handleMode = (e) => {
    const tmpDomain = { ...domain }
    tmpDomain.mode = parseInt(e.target.value)
    tmpDomain.readableMode = addressMode[parseInt(e.target.value)].field
    setdomain(tmpDomain)
  }

  console.log(props.domains)

  return (
    <Modal size="m" show={props.show} dialogClassName="addModal text-center">
      <Modal.Header className="d-flex justify-content-center ">
        <Modal.Title>Add Remote Domain</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {isNonEmptyArray(props.domains) && !props.domains.find((d) => d.is_hub) ? (
            <Form.Check
              style={{ textAlign: "left" }}
              name="isHub"
              value=""
              id="isHub"
              label="IsHub"
              onChange={(e) => handleIsHub(e)}
            />
          ) : !isNonEmptyArray(props.domains) ? (
            <Form.Check
              style={{ textAlign: "left" }}
              name="isHub"
              value=""
              id="isHub"
              label="IsHub"
              onChange={(e) => handleIsHub(e)}
            />
          ) : null}
          <div className="data-row">
            <span>
              Node Name:<span className="mandatory-field"> *</span>
            </span>
            <CrossedInput placeholder="Name" value={domain.domain_name} object={domain} callback={handleName} />
          </div>

          <OverlayTrigger overlay={<Tooltip style={{ position: "absolute" }}> Must be a new domain</Tooltip>}>
            <div className="data-row">
              <span>
                Domain:<span className="mandatory-field"> *</span>
              </span>
              <CrossedInput placeholder="Dns" value={domain.dns} object={domain} callback={handleDns} />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: "absolute" }}>
                {" "}
                Ip addresses must begin with http:// or https:// and must end with /
              </Tooltip>
            }
          >
            <div className="data-row">
              <span>
                Address:<span className="mandatory-field"> *</span>
              </span>
              <CrossedInput
                admittedCharRegex={admittedCharRegex}
                placeholder="Address"
                value={domain.address_1}
                object={domain}
                callback={handleaddress_1}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: "absolute" }}>
                {" "}
                Ip addresses must begin with http:// or https:// and must end with /
              </Tooltip>
            }
          >
            <div className="data-row">
              <span>Address:</span>
              <CrossedInput
                placeholder="Address"
                admittedCharRegex={admittedCharRegex}
                value={domain.address_2}
                object={domain}
                callback={handleaddress_2}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: "absolute" }}>
                {" "}
                Ip addresses must begin with http:// or https:// and must end with /
              </Tooltip>
            }
          >
            <div className="data-row">
              <span>Address:</span>
              <CrossedInput
                placeholder="Address"
                admittedCharRegex={admittedCharRegex}
                value={domain.address_3}
                object={domain}
                callback={handleaddress_3}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: "absolute" }}>
                {" "}
                Ip addresses must begin with http:// or https:// and must end with /
              </Tooltip>
            }
          >
            <div className="data-row">
              <span>Address:</span>
              <CrossedInput
                placeholder="Address"
                admittedCharRegex={admittedCharRegex}
                value={domain.address_4}
                object={domain}
                callback={handleaddress_4}
              />
            </div>
          </OverlayTrigger>

          <div className="data-row">
            <span>
              SNI:<span className="mandatory-field"> *</span>
            </span>

            <CrossedInput placeholder="sepp1.5gc" value={domain.sni} object={domain} callback={handleSni} />
          </div>

          <FormGroup className="flex-grow-1 form-group w-100">
            <FormSelect style={{ textAlign: "left" }} defaultValue="label" onChange={(e) => handleMode(e)}>
              <option value="label" disabled>
                Address Mode
              </option>
              {addressMode.map((mode) => {
                return <option value={mode.value}>{mode.field}</option>
              })}
            </FormSelect>
          </FormGroup>

          {isNonEmptyArray(formErrors) && (
            <div className="upload-errors mt-3">
              <span className="errors-title">Some errors have been found!</span>
              {formErrors.map((err) => (
                <div>- {err}.</div>
              ))}
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          // style={formFilled && !isNonEmptyArray(formErrors) ? {} : { visibility: "hidden" }}
          onClick={() => {
            checkMandatoryFields()
          }}
        >
          Add
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddDomainModal
