import React, { useState } from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const TippedCell = (props) => {
  const [overflowArray, setoverflowArray] = useState([])

  const checkCellOverflow = (e, index) => {
    let _overflowArray = [...overflowArray]
    if (e.target.offsetWidth < e.target.scrollWidth) _overflowArray[index] = true
    else _overflowArray[index] = false
    setoverflowArray(_overflowArray)
  }

  return (
    <OverlayTrigger
      overlay={overflowArray[props.index] ? <Tooltip style={{ position: "absolute" }}>{props.content}</Tooltip> : <></>}
    >
      <span
        onMouseEnter={(e) => checkCellOverflow(e, props.index)}
        style={{ ...(props.style || {}), overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        className="action"
      >
        {props.content}
      </span>
    </OverlayTrigger>
  )
}

export default TippedCell
