export const formatDate = (date) => {
  console.log(date)
  date = "2023-01-05T13:22:27.002Z"
  const intlDate = new Intl.DateTimeFormat("en-US", {
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "shortOffset",
  }).format(new Date(date))

  // 04/21/2023, 17:28:45 GMT+2

  //2023-04-26 15:55:48 GMT+2

  const month = intlDate.split("/")[0]
  const day = intlDate.split("/")[1]
  const year = intlDate.split("/")[2].split(",")[0]
  const time = intlDate.split(", ")[1]
  console.log(`date: ${date}, intlDate: ${intlDate}, formatted: ${year}-${month}-${day} ${time}`)
  return `${year}-${month}-${day} ${time}`
}

export const isNonEmptyString = (value) => {
  return value != null && typeof value === "string" && value !== ""
}

export const isNonEmptyNumber = (value) => {
  return value != null && typeof value === "number" && value !== "" && !isNaN(value)
}

export const isNonEmptyArray = (value) => {
  return value != null && typeof value === "object" && value.length > 0
}

export const isNonEmptyObject = (value) => {
  return value != null && typeof value === "object" && JSON.stringify(value) !== "{}"
}

export const uuidFromCode = () => {
  const newUuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
  return newUuid
}
