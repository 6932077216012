import React, { useState, useEffect, useRef } from "react"
import Spinner from "../UI/Spinner/Spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faSync, faFileImport, faFileExport } from "@fortawesome/free-solid-svg-icons"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Nav from "react-bootstrap/Nav"
import "./Routing.less"
import AddRouteModal from "./AddRouteModal"
import AddDomainModal from "./AddDomainModal"
import { useSnackbar } from "../../contexts/snackbarContext"
import _, { isBuffer } from "lodash"
import ConfirmModal from "../UI/ConfirmModal/ConfirmModal"
import { useConfig } from "../../contexts/configContext"
import { isNonEmptyArray, isNonEmptyObject, isNonEmptyString, uuidFromCode } from "../../utils"
import useHttpApi from "../useHttpApi"
import ListTable from "../SharedComponents/ListTable"

const RoutingTable = (props) => {
  const [loading, setloading] = useState(true)
  const [tab, settab] = useState("routing")
  const [inRoutes, setinRoutes] = useState(null)
  const [domains, setdomains] = useState(null)
  const [whitelist, setwhitelist] = useState(null)
  const [routesToShow, setroutesToShow] = useState(null)
  const [routeModal, setrouteModal] = useState(false)
  const [domainModal, setdomainModal] = useState(false)
  const [openConfirmModal, setopenConfirmModal] = useState(false)
  const [fileToBeUploaded, setfileToBeUploaded] = useState(null)
  const [uploadErrors, setuploadErrors] = useState([])

  const config = useConfig()
  const snackbar = useSnackbar()
  const httpApi = useHttpApi()

  const inputRef = useRef(null)

  const routeHeader = [
    {
      title: "Remote Domain",
      field: "domain_name",
      size: "28%",
    },
    {
      title: "Direction",
      field: "dialogue_mode",
      size: "28%",
    },
    {
      title: "Dialogue Mode",
      field: "direction",
      size: "28%",
    },
    {
      title: "MFW",
      field: "mfw",
      size: "28%",
    },
    {
      title: "",
      field: "",
      size: "4%",
      type: "delete",
    },
    {
      title: "",
      field: "",
      size: "4%",
      type: "show",
    },
  ]

  const domainHeader = [
    {
      title: "Name",
      field: "domain_name",
      size: "10%",
    },
    {
      title: "Domain",
      field: "dns",
      size: "15%",
    },
    {
      title: "Is Hub",
      field: "is_hub",
      size: "10%",
    },
    {
      title: "Address 1",
      field: "address_1",
      size: "10%",
    },
    {
      title: "Address 2",
      field: "address_2",
      size: "10%",
    },
    {
      title: "Address 3",
      field: "address_3",
      size: "10%",
    },
    {
      title: "Address 4",
      field: "address_4",
      size: "10%",
    },
    {
      title: "SNI",
      field: "sni",
      size: "10%",
    },
    {
      title: "Address Mode",
      field: "readableMode",
      size: "10%",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "delete",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "show",
    },
  ]

  const addressMode = [
    { field: "Round-Robin", value: 0 },
    { field: "Primary/Secondary", value: 1 },
  ]

  useEffect(() => {
    props.setBreadcrumb("", "Routing", "")
  }, [])

  const normalizeRows = (header, jsonArray) => {
    header.forEach((h) => {
      jsonArray.forEach((json) => {
        if (h.field !== "" && !json.hasOwnProperty(h.field)) json[h.field] = ""
        if (h.field === "readableMode") {
          json[h.field] = addressMode[parseInt(json.mode)].field
        }
      })
    })
    return jsonArray
  }

  const makeDomainsDeleteable = (allRoutes, _domains) => {
    //we don't have routes but only domains, so domains can be deleteable

    if (!isNonEmptyArray(allRoutes) && isNonEmptyArray(_domains)) {
      return _domains.map((domain) => {
        domain.deleteable = true
        return domain
      })
      //we have both routes and domains, so we make deleteable only the ones that are not route also
    } else if (isNonEmptyArray(_domains) && isNonEmptyArray(allRoutes)) {
      return _domains.map((domain) => {
        if (allRoutes.find((route) => route.domain_name === domain.domain_name)) domain.deleteable = false
        else domain.deleteable = true
        return domain
      })
    } else return []
  }

  const buildDomainsAndRoutes = async () => {
    let _domains = await fetchDomains()
    _domains = isNonEmptyArray(_domains.domains) ? _domains.domains : []

    let allRoutes = await fetchRoutes()
    const inbound = isNonEmptyArray(allRoutes.routes) ? allRoutes.routes : []
    const outbound = isNonEmptyArray(allRoutes.whitelist) ? allRoutes.whitelist : []
    allRoutes = [...inbound, ...outbound]

    allRoutes = normalizeRows(routeHeader, allRoutes)
    allRoutes.forEach((r) => (r.deleteable = true))
    _domains = normalizeRows(domainHeader, _domains)
    _domains = makeDomainsDeleteable(allRoutes, _domains)

    setdomains(_domains)
    setinRoutes(inbound)
    setwhitelist(outbound)
    setroutesToShow(allRoutes)
  }

  useEffect(() => {
    if (!isNonEmptyArray(inRoutes) || !isNonEmptyArray(domains) || !isNonEmptyArray(whitelist)) {
      buildDomainsAndRoutes()
    }
  }, [])

  //if data have been fetched, we set loading to false
  //When one of the following has no data, it just has an empty array (not null)
  useEffect(() => {
    if (inRoutes != null && domains != null && whitelist != null) setloading(false)
  }, [inRoutes, domains, whitelist])

  //Creating  Redis key, if necessary
  const createRedisKey = (key, data) => {
    httpApi.callPostAPI({ url: key, data, snack: { show: false } })
  }

  const needToCreateRedisKey = async () => {
    return Promise.all([
      httpApi.callGetAPI({
        url: "json/routing",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/routing", [])
          return "routing"
        },
        successCallback: (data) => 1,
      }),
      httpApi.callGetAPI({
        url: "json/whitelist",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/whitelist", [])
          return "whitelist"
        },
        successCallback: (data) => 1,
      }),
      httpApi.callGetAPI({
        url: "json/domaintable",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/domaintable", [])
          return "domaintable"
        },
        successCallback: (data) => 1,
      }),
    ])
  }

  useEffect(() => {
    needToCreateRedisKey().then((data) => {
      console.log(data)
      if (data.filter((el) => el !== 1).length > 0)
        snackbar.setSnackbarInfo({
          status: "warning",
          message: `The following requested <b>Redis keys</b> are missing and will be created: <i>${data.join(
            ", "
          )}</i>`,
        })
    })
  }, [])

  const fetchRoutes = async () => {
    let routes = []
    let whitelist = []

    routes = await httpApi.callGetAPI({
      snack: { show: false },
      url: "json/routing",
    })
    whitelist = await httpApi.callGetAPI({
      url: "json/whitelist",
      snack: { show: false },
    })

    return { routes, whitelist }
  }

  const fetchDomains = async () => {
    let domains = []

    domains = await httpApi
      .callGetAPI({
        url: "json/domaintable",
        snack: { show: false },
      })
      .then((data) => data)

    return {
      domains,
    }
  }

  const routeModalShow = () => {
    setrouteModal(true)
  }

  const routeModalHide = () => {
    setrouteModal(false)
  }
  const domainModalShow = () => {
    setdomainModal(true)
  }

  const domainModalHide = () => {
    setdomainModal(false)
  }

  const addInOutRoutes = async (_routes, dialogueMode) => {
    await httpApi.callPostAPI({
      url: dialogueMode === "inbound" ? `json/routing` : `json/whitelist`,
      snack: { show: true },
      data: _routes,
      successCallback: async () => {
        buildDomainsAndRoutes()
      },
    })
  }

  const addRoute = async (route) => {
    let _inRoutes = [...inRoutes]
    let _whitelist = [...whitelist]
    if (route.dialogue_mode === "inbound") {
      _inRoutes = [..._inRoutes, route]
      addInOutRoutes(_inRoutes, "inbound")
    }
    if (route.dialogue_mode === "outbound") {
      _whitelist = [..._whitelist, route]
      addInOutRoutes(_whitelist, "outbound")
    }
  }

  const deleteRoute = async (route) => {
    let _routes = []
    if (route.dialogue_mode === "inbound") {
      _routes = inRoutes.filter((r) => r.id !== route.id)
    } else if (route.dialogue_mode === "outbound") {
      _routes = whitelist.filter((r) => r.id !== route.id)
    }

    await httpApi.callPostAPI({
      url: `${route.dialogue_mode === "inbound" ? "json/routing" : "json/whitelist"}`,
      data: _routes,
      snack: { show: true, success: { status: "success", message: "Route correctly deleted" } },
      successCallback: async () => {
        buildDomainsAndRoutes()
      },
    })
  }

  const addDomain = async (domain) => {
    const tmpDomains = [...domains, domain]
    tmpDomains.forEach((d) => {
      if (d.hasOwnProperty("readableMode")) delete d.readableMode
      if (d.hasOwnProperty("deleteable")) delete d.deleteable
    })

    await httpApi.callPostAPI({
      url: `json/domaintable`,
      data: tmpDomains,
      snack: { show: true, success: { status: "success", message: "Route domain correctly added" } },
      successCallback: async () => {
        buildDomainsAndRoutes()
      },
    })
  }

  const deleteDomain = async (domain) => {
    const tmpDomains = domains.filter((r) => r.id !== domain.id)
    await httpApi.callPostAPI({
      url: `json/domaintable`,
      data: tmpDomains,
      snack: { show: true, success: { status: "success", message: "Route domain correctly deleted" } },
      successCallback: async () => {
        buildDomainsAndRoutes()
      },
    })
  }

  const sendRedis = async () => {
    const _domains = await httpApi.callGetAPI({ url: "json/domaintable", snack: { show: false } })
    const _inRoutes = await httpApi.callGetAPI({ url: "json/routing", snack: { show: false } })
    const _whitelist = await httpApi.callGetAPI({ url: "json/whitelist", snack: { show: false } })

    let inRouteTable = _domains
      .filter((d) =>
        _inRoutes.find((r) => {
          if (r.domain_name === d.domain_name) {
            d.direction = r.direction
            return d
          }
        })
      )
      .map((rt) => {
        if (rt.direction === "Hub") {
          return {
            [rt.dns]: [_domains.find((d) => d.is_hub === true).address_1],
          }
        } else
          return {
            [rt.dns]: rt.addresses.filter((r) => r !== ""),
          }
      })

    let outRouteTable = _domains
      .filter((d) =>
        _whitelist.find((r) => {
          if (r.domain_name === d.domain_name) {
            d.direction = r.direction
            return d
          }
        })
      )
      .map((rt) => {
        if (rt.direction === "Hub") {
          return {
            [rt.dns]: [_domains.find((d) => d.is_hub === true).address_1],
          }
        } else
          return {
            [rt.dns]: rt.addresses.filter((r) => r !== ""),
          }
      })

    let filteredDomains = _domains
      .filter((d) =>
        [..._inRoutes, ..._whitelist].find((r) => {
          if (r.domain_name === d.domain_name) {
            d.isFqdn = r.mfw.includes("TelescopicFQDN")
            return d
          }
        })
      )
      .map((fd) => {
        return { [fd.dns]: fd.isFqdn }
      })

    let sni = _domains.map((d) => ({ [d.dns]: d.sni ? `${d.sni}.${d.dns}` : d.dns }))

    //From an array of objects to an object of key-value pairs
    inRouteTable = Object.assign({}, ...inRouteTable)
    outRouteTable = Object.assign({}, ...outRouteTable)
    outRouteTable = Object.keys(outRouteTable).map((k) => k)
    filteredDomains = Object.assign({}, ...filteredDomains)
    sni = Object.assign({}, ...sni)

    callSendRedisApi({ sni, inRouteTable, outRouteTable, filteredDomains }).then((data) => {
      if (data.filter((el) => el !== 1).length > 0)
        snackbar.setSnackbarInfo({
          status: "error",
          message: `There was an error on saving on the following <b>Redis keys</b>: <i>${data.join(", ")}</i>`,
        })
    })
  }

  const callSendRedisApi = async (obj) => {
    return Promise.all([
      // the route table API must be called after the snihostname
      httpApi.callPostAPI({
        url: `json/${config.configInfo.protectionGroupID}snihostname`,
        data: obj.sni,
        successCallback: () => {
          return httpApi.callPostAPI({
            url: `json/${config.configInfo.protectionGroupID}route-table`,
            data: obj.inRouteTable,
            successCallback: () => 1,
            errorCallback: () => `${config.configInfo.protectionGroupID}route-table`,
          })
        },
        errorCallback: () => `${config.configInfo.protectionGroupID}snihostname`,
      }),

      httpApi.callPostAPI({
        url: `json/${config.configInfo.protectionGroupID}domain_whitelist`,
        data: obj.outRouteTable,
        successCallback: () => 1,
        errorCallback: () => `${config.configInfo.protectionGroupID}domain_whitelist`,
      }),

      httpApi.callPostAPI({
        url: `json/${config.configInfo.protectionGroupID}fqdn-table`,
        data: obj.filteredDomains,
        successCallback: () => 1,
        errorCallback: () => `${config.configInfo.protectionGroupID}fqdn-table`,
      }),
    ])
  }

  const importFile = () => {
    inputRef.current.click()
  }

  const handleOnChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setfileToBeUploaded(e.target.files[0])
      e.target.value = ""
    }
  }

  const uploadFile = async (url, formData, type) => {
    return httpApi.callUploadAPI({
      url,
      formData,
      snack: { success: { status: "success", message: `${type} Table successfully uploaded` } },
    })
  }

  const checkRoutingImportedData = (data) => {
    if (!Array.isArray(data)) return "The file must contain a non empty array of data"
    if (data.length === 0) return "The file must contain a non empty array of data"

    const mfwType = ["ApiRoot", "TelescopicFQDN"]
    const directionType = ["Direct"]
    const dialogueModeType = ["inbound", "outbound"]
    const remoteDomainType = domains.map((d) => d.domain_name)
    const errors = new Set()

    data.forEach((obj) => {
      if (!obj.hasOwnProperty("id")) obj.id = uuidFromCode()
      if (Object.keys(obj).length > 5) errors.add("Your routes have too many properties")
      if (!obj.hasOwnProperty("domain_name")) errors.add("Some routes are missing the 'domain_name' property")
      else if (!remoteDomainType.find((el) => el === obj.domain_name))
        errors.add(
          `The route of name '${
            obj.domain_name
          }' have no corrisponding domain. Choose one among: ${remoteDomainType.join(", ")}`
        )
      if (!obj.hasOwnProperty("dialogue_mode")) errors.add("Some routes are missing the 'dialogue_mode' property")
      else if (!dialogueModeType.find((el) => el === obj.dialogue_mode))
        errors.add(
          `The 'dialogue_mode' property of value '${
            obj.dialogue_mode
          }' is wrong. Choose one among: ${dialogueModeType.join(", ")}`
        )
      else {
        if (obj.dialogue_mode === "inbound") {
          if (!obj.hasOwnProperty("direction")) errors.add(`Some inbound routes are missing the 'direction' property`)
          else {
            if (!directionType.find((el) => el === obj.direction))
              errors.add(
                `Your inbound route has wrong 'direction' value '${
                  obj.direction
                }'. Choose one among: ${directionType.join(", ")}`
              )
          }
          if (!obj.hasOwnProperty("mfw")) errors.add(`Some inbound routes are missing the 'mfw' property`)
          else {
            if (!mfwType.find((el) => el === obj.mfw)) {
              errors.add(
                `Your inbound routes have wrong 'mfw' value '${obj.mfw}'. Choose one among: ${mfwType.join(", ")}`
              )
            }
          }
        }
      }
    })
    return errors
  }

  const checkDomainImportedData = (data) => {
    if (!Array.isArray(data)) return "The file must contain a non empty array of data"
    if (data.length === 0) return "The file must contain a non empty array of data"
    if (Object.keys(data).length > 11) errors.push("Your domains have too many properties")

    const errors = new Set()
    const modeType = ["0", "1"]
    const hubType = ["true", "false"]
    const addressRegex = /https?:\/\/(s)?[a-zA-Z0-9\.\-\?\=\&\:]+\//g

    data.forEach((el) => {
      if (el.hasOwnProperty("deleteable")) delete el.deleteable
      if (el.hasOwnProperty("readableMode")) delete el.readableMode

      if (!el.hasOwnProperty("id")) el.id = uuidFromCode()

      if (!el.hasOwnProperty("domain_name")) errors.add("Some domains are missing the 'domain_name' property")
      else if (!isNonEmptyString(el.domain_name)) errors.add("You cannot leave the 'domain_name' property empty")

      if (!el.hasOwnProperty("dns")) errors.add("Some domains are missing the 'dns' property")
      else if (!isNonEmptyString(el.dns)) errors.add("You cannot leave the 'dns' property empty")

      if (!el.hasOwnProperty("is_hub")) el.is_hub = false
      else if (el.is_hub.toString() !== "false" && el.is_hub.toString() !== "true")
        errors.add(`Some domains have a wrong 'is_hub' value '${el.is_hub}'. Choose one among ${hubType.join(", ")}`)

      if (!el.hasOwnProperty("address_1")) errors.add("Some domains are missing the 'address_1' property")
      else if (!isNonEmptyString(el.address_1)) errors.add("You cannot leave the 'address_1' property empty")
      else if (!el.address_1.match(addressRegex))
        errors.add(
          `Some domains have a wrong 'address_1' value '${el.address_1}'. Make sure it begins with 'http(s)://' and ends with '/'`
        )

      if (!el.hasOwnProperty("sni")) errors.add("Some domains are missing the 'sni' property")
      else if (!isNonEmptyString(el.sni)) errors.add("You cannot leave the 'sni' property empty")

      if (el.hasOwnProperty("mode")) {
        if (!modeType.find((m) => m === el.mode.toString()))
          errors.add(`Some domains have a wrong 'mode' value '${el.mode}'. Choose one among ${modeType.join(", ")}`)
      }

      if (el.hasOwnProperty("address_2") && isNonEmptyString(el.address_2))
        if (!el.address_2.match(addressRegex))
          errors.add(
            `Some domains have a wrong 'address_2' value '${el.address_2}'. Make sure it begins with 'http(s)://' and ends with '/'`
          )

      if (el.hasOwnProperty("address_3") && isNonEmptyString(el.address_3))
        if (!el.address_3.match(addressRegex))
          errors.add(
            `Some domains have a wrong 'address_3' value '${el.address_3}'. Make sure it begins with 'http(s)://' and ends with '/'`
          )

      if (el.hasOwnProperty("address_4") && isNonEmptyString(el.address_4))
        if (!el.address_4.match(addressRegex))
          errors.add(
            `Some domains have a wrong 'address_4' value '${el.address_4}'. Make sure it begins with 'http(s)://' and ends with '/'`
          )

      if (el.hasOwnProperty("addresses")) {
        if (!Array.isArray(el.addresses))
          errors.add(`Some domains have wrong property type of 'addresses'. Make sure it is an array.`)
        else {
          let addError = false
          el.addresses.forEach((add) => {
            if (!add.match(addressRegex)) addError = true
          })
          if (addError)
            errors.add(
              `Some domains have wrong values for property 'addresses'. Make sure they begin with 'http(s)://' and end with '/'`
            )
        }
      }
    })

    return errors
  }

  const checkImportedData = (data, type) => {
    if (type === "routing") return checkRoutingImportedData(data)
    if (type === "domain") return checkDomainImportedData(data)
    return []
  }

  const onReaderLoad = async (e, tab) => {
    let data = JSON.parse(e.target.result)

    const errors = Array.from(checkImportedData(data, tab))
    if (errors.length > 0) {
      setuploadErrors(errors)
      return
    }

    if (tab === "routing") {
      const inFormData = new FormData()
      const outFormData = new FormData()
      const _inRoutes = data.filter((route) => route.dialogue_mode === "inbound")
      const _whitelist = data.filter((route) => route.dialogue_mode === "outbound")
      inFormData.append("file", new Blob([JSON.stringify(_inRoutes)]))
      outFormData.append("file", new Blob([JSON.stringify(_whitelist)]))

      const res = await Promise.all([
        uploadFile(`file/routing`, inFormData, "Routing"),
        uploadFile(`file/whitelist`, outFormData, "Whitelist"),
      ])
      if (!res.find((r) => r.status !== 200)) {
        setinRoutes(_inRoutes)
        setwhitelist(_whitelist)
        setroutesToShow([..._inRoutes, ..._whitelist])
      }
    } else {
      const formData = new FormData()
      formData.append("file", new Blob([JSON.stringify(data)]))
      const res = await uploadFile(`file/domaintable`, formData, "Domain")
      if (res.status === 200) {
        buildDomainsAndRoutes()
      }
    }
  }

  const uploadTable = (tab) => {
    const reader = new FileReader()
    reader.onload = (e) => onReaderLoad(e, tab)
    reader.readAsText(fileToBeUploaded)
  }

  const performExport = (data, tab) => {
    if (data) {
      const fileName = `${tab === "routing" ? "routingTable" : "domainTable"}`
      const json = JSON.stringify(data, null, 2)
      const blob = new Blob([json], { type: "application/json" })
      const href = URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.href = href
      link.download = fileName + ".json"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)

      snackbar.setSnackbarInfo({
        status: "success",
        message: `${tab === "routing" ? "Routing" : "Domain"} Table successfully exported`,
      })
    } else {
      snackbar.setSnackbarInfo({
        status: "error",
        message: "Something went wrong",
      })
    }
  }

  const exportFile = async () => {
    if (tab === "routing") {
      const result = await Promise.all([
        httpApi.callGetAPI({ url: "json/routing" }).then((data) => data),
        httpApi.callGetAPI({ url: "json/whitelist" }).then((data) => data),
      ])
      performExport([...result[0], ...result[1]], tab)
    } else {
      const result = await httpApi.callGetAPI({ url: "json/domaintable" })
      performExport(result, tab)
    }
  }

  return (
    <div className="page">
      <Spinner show={loading} />
      <ConfirmModal
        bsSize="medium"
        show={openConfirmModal}
        onHide={() => setopenConfirmModal(false)}
        onClose={() => setopenConfirmModal(false)}
        title="Upload "
        className="text-center"
        onAccept={(e) => {
          // setuploadErrors([])
          setopenConfirmModal(false)
          uploadTable(tab)
        }}
        closeText="Close"
      >
        <div>
          <p>This action will permanently replace the current redis key with the one you are providing in the file!</p>
          <p>Do you want to continue?</p>
        </div>
      </ConfirmModal>
      {!loading && (
        <>
          <Nav defaultActiveKey="routing" className="tabRouting" fill variant="tabs">
            <Nav.Item onClick={() => settab("routing")}>
              <Nav.Link eventKey="routing">ROUTING TABLE</Nav.Link>
            </Nav.Item>
            <Nav.Item
              onClick={() => {
                //if we deleted some routes, when we switch to domain tab we need to check if some domains could be now deleatable
                buildDomainsAndRoutes()
                settab("domain")
              }}
            >
              <Nav.Link eventKey="domain">REMOTE DOMAIN TABLE</Nav.Link>
            </Nav.Item>
          </Nav>
          {tab === "routing" && (
            <>
              <AddRouteModal
                show={routeModal}
                onHide={routeModalHide}
                addRoute={addRoute}
                domains={domains}
                routes={inRoutes}
                whitelist={whitelist}
              />
              <div className="routing-panel">
                {/* <RoutesList routes={routesToShow || []} deleteRoute={deleteRoute} /> */}
                {routesToShow.length > 0 ? (
                  <ListTable
                    elements={routesToShow}
                    header={routeHeader}
                    sortable
                    searchable
                    collapsible
                    dense
                    cellActions={[
                      {
                        elementName: "route",
                        type: "delete",
                        method: deleteRoute,
                        size: routeHeader.find((h) => h.hasOwnProperty("type") && h.type === "delete")?.size,
                      },
                      {
                        elementName: "route",
                        type: "show",
                        size: routeHeader.find((h) => h.hasOwnProperty("type") && h.type === "show")?.size,
                      },
                    ]}
                  ></ListTable>
                ) : (
                  <h3 class="no-table-data">There are no data to be shown yet</h3>
                )}

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip style={{ position: "absolute", zIndex: "100001" }}>Add Route</Tooltip>}
                >
                  <div
                    style={{
                      ...(!isNonEmptyArray(domains) ? { opacity: "0.3" } : { opacity: "1" }),
                    }}
                    onClick={() => isNonEmptyArray(domains) && routeModalShow()}
                    className="addBtn action routingBtnPosition first"
                  >
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: "absolute", zIndex: "100001" }}>
                      {inRoutes ? "Send To Redis" : "Send Empty Table To Redis"}
                    </Tooltip>
                  }
                >
                  <div onClick={() => sendRedis()} className="addBtn send action routingBtnPosition second">
                    <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: "absolute", zIndex: "100001" }}>Import Routes From JSON File</Tooltip>
                  }
                >
                  <div
                    style={{
                      ...(!isNonEmptyArray(domains) ? { opacity: "0.3" } : { opacity: "1" }),
                    }}
                    onClick={() => importFile()}
                    className="addBtn import action routingBtnPosition third"
                  >
                    <input
                      disabled={!isNonEmptyArray(domains)}
                      ref={inputRef}
                      type="file"
                      accept="application/JSON"
                      id="jsonUpload"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleOnChange(e)
                        setopenConfirmModal(true)
                      }}
                    />
                    <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: "absolute", zIndex: "100001" }}>Export Routes To JSON File</Tooltip>
                  }
                >
                  <div
                    style={{
                      ...(!isNonEmptyArray(routesToShow) ? { opacity: "0.3" } : { opacity: "1" }),
                    }}
                    onClick={() => isNonEmptyArray(routesToShow) && exportFile()}
                    className="addBtn export action routingBtnPosition fourth"
                  >
                    <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>
                {uploadErrors.length > 0 && (
                  <div className="upload-errors">
                    <span className="errors-title">Some errors have been found on your file!</span>
                    {uploadErrors.map((err) => (
                      <div>- {err}.</div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
          {tab === "domain" && (
            <>
              <AddDomainModal domains={domains} show={domainModal} onHide={domainModalHide} addDomain={addDomain} />
              <div className="domain-panel">
                {/* <DomainList domains={domains || []} routes={routesToShow || []} deleteDomain={deleteDomain} /> */}
                {domains.length > 0 ? (
                  <ListTable
                    elements={domains}
                    header={domainHeader}
                    sortable
                    searchable
                    collapsible
                    dense
                    cellActions={[
                      {
                        elementName: "domain",
                        type: "delete",
                        method: deleteDomain,
                        size: domainHeader.find((h) => h.hasOwnProperty("type") && h.type === "delete")?.size,
                      },
                      {
                        elementName: "domain",
                        type: "show",
                        size: domainHeader.find((h) => h.hasOwnProperty("type") && h.type === "show")?.size,
                      },
                    ]}
                  ></ListTable>
                ) : (
                  <h3 class="no-table-data">There are no data to be shown yet</h3>
                )}
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip style={{ position: "absolute", zIndex: "100001" }}>Add Remote Domain</Tooltip>}
                >
                  <div onClick={() => domainModalShow()} className="addBtn action routingBtnPosition first">
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: "absolute", zIndex: "100001" }}>
                      Import Domain Table From JSON File
                    </Tooltip>
                  }
                >
                  <div onClick={() => importFile()} className="addBtn import action routingBtnPosition second">
                    <input
                      ref={inputRef}
                      type="file"
                      accept="application/JSON"
                      id="jsonUpload"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleOnChange(e)
                        setopenConfirmModal(true)
                      }}
                    />
                    <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: "absolute", zIndex: "100001" }}>
                      Export Domain Table To JSON File
                    </Tooltip>
                  }
                >
                  <div
                    style={{
                      ...(!isNonEmptyArray(domains) ? { opacity: "0.3" } : { opacity: "1" }),
                    }}
                    onClick={() => isNonEmptyArray(domains) && exportFile()}
                    className="addBtn export action routingBtnPosition third"
                  >
                    <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>
                {uploadErrors.length > 0 && (
                  <div className="upload-errors">
                    <span className="errors-title">Some errors have been found on your file!</span>
                    {uploadErrors.map((err) => (
                      <div>- {err}.</div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export { RoutingTable as Routing }
