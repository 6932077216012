import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import Nav from "react-bootstrap/Nav"
import { useConfig } from "../../contexts/configContext"
import ListTable from "../SharedComponents/ListTable"
import useHttpApi from "../useHttpApi"
import Modal from "react-bootstrap/Modal"

function Tdr(props) {
  const [cvasTdr, setcvasTdr] = useState([])
  const [pvasTdr, setpvasTdr] = useState([])
  const [showModal, setshowModal] = useState(false)
  const [modalContent, setmodalContent] = useState(null)
  const [tab, settab] = useState("cvas")

  const config = useConfig()
  const httpApi = useHttpApi()

  const inboundHeader = [
    {
      title: "Method",
      field: "method",
      size: "7%",
    },
    {
      title: "Path",
      field: "path",
      size: "20%",
    },
    {
      title: "User-Agent",
      field: "user-agent",
      size: "10%",
    },
    {
      title: "Apiroot",
      field: "apiroot",
      size: "17%",
    },
    {
      title: "Destination PLMN",
      field: "plmn",
      size: "13%",
    },
    {
      title: "Response",
      field: "response",
      size: "10%",
    },
    {
      title: "Timestamp",
      field: "timestamp",
      size: "20%",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "show",
    },
  ]

  const outboundHeader = [
    {
      title: "Method",
      field: "method",
      size: "7%",
    },
    {
      title: "Path",
      field: "path",
      size: "20%",
    },
    {
      title: "User-Agent",
      field: "user-agent",
      size: "10%",
    },
    {
      title: "Apiroot",
      field: "apiroot",
      size: "17%",
    },
    {
      title: "Source PLMN",
      field: "plmn",
      size: "13%",
    },
    {
      title: "Response",
      field: "response",
      size: "10%",
    },
    {
      title: "Timestamp",
      field: "timestamp",
      size: "20%",
    },
    {
      title: "",
      field: "",
      size: "7%",
      type: "show",
    },
  ]

  useEffect(() => {
    props.setBreadcrumb("", "TDR", "")
  }, [])

  const formatTime = (vas) => {
    return vas.map((el) => {
      const dateTime = el.timestamp.replace("T", " ").split(".")
      const date = dateTime[0]
      const timezone = dateTime[1].includes("+") ? dateTime[1].split("+")[1] : dateTime[1].split("-")[1]
      el.timestamp = dateTime[1].includes("+") ? `${date} +${timezone}` : `${date} -${timezone}`
      return el
    })
  }

  useEffect(() => {
    fetchTDR().then((res) => {
      res && res[0] ? setcvasTdr(formatTime(res[0])) : setcvasTdr([])
      res && res[1] ? setpvasTdr(formatTime(res[1])) : setpvasTdr([])
    })
  }, [])

  const fetchTDR = async () => {
    await httpApi.callGetAPI({ url: "list_json/cvas_tdr_repo" })
    let res = Promise.all([
      await httpApi.callGetAPI({ url: "list_json/cvas_tdr_repo" }),
      await httpApi.callGetAPI({ url: "list_json/pvas_tdr_repo" }),
    ])
    return res
  }

  const resetTdr = async () => {
    await httpApi.callDeleteAPI({
      url: "h_reset/cvas_tdr_repo",
      snack: { success: { status: "success", message: "TDR Table successfully reset" } },
    })

    fetchTDR().then((res) => {
      res && res[0] ? setcvasTdr(res[0]) : setcvasTdr([])
      res && res[1] ? setpvasTdr(res[1]) : setpvasTdr([])
    })
  }

  const downloadTdr = async () => {
    await httpApi.callDownloadAPI(
      {
        url: `list_json/${tab}_tdr_repo/export`,
        snack: {
          success: {
            status: "success",
            message: "TDR Table successfully exported",
          },
        },
      },
      { name: `${tab === "cvas" ? "Inbound" : "Outbound"}TDRTable`, type: "excel" }
    )
  }

  const getMessages = async (vasType) => {
    const data = await httpApi.callGetAPI({
      url: vasType === "cvas" ? "json/cSEPP_last_tdr_audit" : "json/pSEPP_last_tdr_audit",
    })
    if (data) setmodalContent(data)
    else {
      data = `There are no samples to show`
      setmodalContent(data)
    }
    setshowModal(true)
  }

  return (
    <>
      {/* <Spinner show={config.loading} /> */}
      {config.loading ? null : (
        <>
          <Modal size="lg" show={showModal} dialogClassName="tdrModal text-center">
            <Modal.Header className="d-flex justify-content-center ">
              <Modal.Title>Last Sample</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: "auto", maxHeight: "70vh" }}>
              <pre style={{ textAlign: "left" }}>{JSON.stringify(modalContent, null, 2)}</pre>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setmodalContent(null)
                  setshowModal(false)
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Nav defaultActiveKey="cvas" className="tabCvas" fill variant="tabs">
            <Nav.Item onClick={() => settab("cvas")}>
              <Nav.Link eventKey="cvas">Inbound TDR</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => settab("pvas")}>
              <Nav.Link eventKey="pvas">Outbound TDR</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="page">
            {tab === "cvas" &&
              (cvasTdr.length > 0 ? (
                <ListTable
                  elements={cvasTdr}
                  header={inboundHeader}
                  sortable
                  searchable
                  collapsible
                  dense
                  cellActions={[
                    {
                      elementName: "TDR",
                      type: "show",
                      size: inboundHeader.find((h) => h.hasOwnProperty("type") && h.type === "show")?.size,
                    },
                  ]}
                ></ListTable>
              ) : (
                <h3 className="no-table-data">No Inbound TDR data available</h3>
              ))}
            {tab === "pvas" &&
              (pvasTdr.length > 0 ? (
                <ListTable
                  elements={pvasTdr}
                  header={outboundHeader}
                  sortable
                  searchable
                  dense
                  cellActions={[
                    {
                      elementName: "TDR",
                      type: "show",
                      size: outboundHeader.find((h) => h.hasOwnProperty("type") && h.type === "show")?.size,
                    },
                  ]}
                ></ListTable>
              ) : (
                <h3 className="no-table-data">No Outbound TDR data available</h3>
              ))}
            <div className="rowDiv j-right list-group" style={{ marginRight: "1.5%", marginTop: "0px" }}>
              <Button onClick={() => (tab === "cvas" ? getMessages("cvas") : getMessages("pvas"))}>
                SHOW LAST SAMPLE
              </Button>
              <Button onClick={() => downloadTdr()} style={{ marginLeft: "20px" }}>
                DOWNLOAD
              </Button>
              <Button onClick={() => resetTdr()} style={{ marginLeft: "20px" }}>
                RESET ALL
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Tdr
