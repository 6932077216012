import { faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useEffect, useRef } from "react"
import { Form, Modal, OverlayTrigger, Tooltip, Button } from "react-bootstrap"

const ShowAction = (props) => {
  const [showModal, setshowModal] = useState(false)
  const modalContent = props.element

  return (
    <>
      <Modal size="lg" show={showModal} dialogClassName="tdrModal text-center">
        <Modal.Header className="d-flex justify-content-center ">
          <Modal.Title>JSON Data</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "auto", maxHeight: "70vh", textAlign: "left" }}>
          <pre>{JSON.stringify(modalContent, null, 2)}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setshowModal(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <span className="actions-container inline-actions-container around" style={{ maxWidth: props.size, zIndex: 0 }}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip style={{ position: "absolute" }} id="1">
              Show JSON
            </Tooltip>
          }
        >
          <span>
            <FontAwesomeIcon
              icon={faEye}
              className="action-clickable"
              onClick={(e) => {
                setshowModal(true)
              }}
            ></FontAwesomeIcon>
          </span>
        </OverlayTrigger>
      </span>
    </>
  )
}

export default ShowAction
