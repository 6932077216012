import React from "react"
import { Route, Navigate, Routes } from "react-router"
import Layout from "./components/Layout"
import { Services } from "./components/Services/Services"
import { Routing } from "./components/Routing/Routing"
import withErrorBoundary from "./services/withErrorBoundary"
import { SnackbarProvider, useSnackbar } from "./contexts/snackbarContext"

import "./App.less"
import { ConfigProvider } from "./contexts/configContext"
import Homepage from "./components/Home/Homepage"
import { Hostname } from "./components/Hostname/Hostname"
import ErrorPage from "./components/ErrorPage/ErrorPage"
import DebugLayout from "./DebugLayout"
import Monitoring from "./components/Monitoring/Monitoring"
import KPI from "./components/KPI/KPI"
import Tdr from "./components/TDR/Tdr"

const App = (props) => {
  // const userLogged = localStorage.getItem('JWT_Token') ? true : false;

  const routes = [
    { route: "/", Component: Homepage },
    { route: "/routing", Component: Routing },
    { route: "/home", Component: Homepage },
    { route: "/hostname", Component: Hostname },
    { route: "/kpi", Component: KPI },
    { route: "/monitoring", Component: Monitoring },
    { route: "/tdr", Component: Tdr },
  ]

  const userLogged = true
  const checkIsLogged = ({ route, Component }, breadcrumbCallback) => {
    if (userLogged)
      return <Route path={route} element={<Component {...props} setBreadcrumb={breadcrumbCallback} />} exact></Route>
    else return <Route path={route} element={<Component {...props} setBreadcrumb={breadcrumbCallback} />}></Route>
  }

  return (
    <SnackbarProvider>
      <ConfigProvider>
        <Layout>
          {(breadcrumbCallback) =>
            userLogged === false ? (
              <Navigate to="/login" />
            ) : (
              <Routes>
                {/* <Route element={<DebugLayout />}> */}
                {routes.map((route) => checkIsLogged(route, breadcrumbCallback))}
                <Route path="*" element={<ErrorPage />} />
                {/* </Route> */}
              </Routes>
            )
          }
        </Layout>
      </ConfigProvider>
    </SnackbarProvider>
  )
}

export default withErrorBoundary(App)
//<Route path='/groups' render={(props) => <ProtectionGroups {...props} setBreadcrumb={breadcrumbCallback} />} exact />
