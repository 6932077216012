import React from "react"
import { LinkContainer } from "react-router-bootstrap"
import "./Breadcrumb.less"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

const Breadcrumb = (props) => {
  return (
    <div style={{ width: props.size || "100%" }} className="breadcrumb">
      {props.label ? (
        <div className="back">
          {typeof props.url === "function" ? (
            <div className="action" onClick={props.url}>
              <FontAwesomeIcon icon={faArrowLeft} />
              <h3>{props.label}</h3>
            </div>
          ) : (
            <LinkContainer to={props.url} exact className="action">
              <div>
                <FontAwesomeIcon icon={faArrowLeft} />
                <h3>{props.label}</h3>
              </div>
            </LinkContainer>
          )}
        </div>
      ) : null}
      <span className="position">{props.position}</span>
    </div>
  )
}

export default Breadcrumb
